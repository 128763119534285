import { useContext, useEffect, useState } from "react";
import { withNaming } from "@bem-react/classname";
import { LocaleContext } from "../layouts/LocaleContext";

export const createClassName = (block) => withNaming({ e: "__", m: "_", v: "_" })(block);

export const createID = (elem) => elem.split(" ").join("-");

export const setLocaleContent = (href, sep = "/") => {
  const { localePath } = useContext(LocaleContext);

  if (href.startsWith("http")) {
    return href;
  }

  return `${localePath === "ru" ? sep : ""}${localePath}${href}`;
};

const scrollBottom = (listComponents, listVideos, top, bottom) => {
  for (let i = 0; i < listComponents.length; i++) {
    if (listComponents[i] && listVideos[i]) {
      const { offsetTop, offsetHeight } = listComponents[i];

      if (bottom > offsetTop + offsetHeight && listVideos[i].paused) {
        if (
          listVideos[i].paused
          && listVideos[i + 1]
          && !listVideos[i + 1].paused
        ) {
          // nothing here
        } else {
          if (listVideos[i - 1] && !listVideos[i - 1].paused) {
            listVideos[i - 1].pause();
          }
          listVideos[i].play();
        }
      }

      if (offsetTop + offsetHeight / 2 < top && !listVideos[i].paused) {
        listVideos[i].pause();
      }
    }
  }
};

const scrollTop = (listComponents, listVideos, top, bottom) => {
  for (let i = 0; i < listComponents.length; i++) {
    if (listComponents[i] && listVideos[i]) {
      const { offsetTop, offsetHeight } = listComponents[i];

      if (offsetTop > top && listVideos[i].paused) {
        if (
          listVideos[i].paused
          && listVideos[i - 1]
          && !listVideos[i - 1].paused
        ) {
          // nothing here
        } else {
          if (listVideos[i + 1] && !listVideos[i + 1].paused) {
            listVideos[i + 1].pause();
          }
          listVideos[i].play();
        }
      }

      if (offsetTop + offsetHeight / 2 > bottom && !listVideos[i].paused) {
        listVideos[i].pause();
      }
    }
  }
};

export const switchPlayVideo = (listComponents, listVideos) => {
  let prevPositionY = 0;
  const height = document.documentElement.clientHeight;
  const headerHeight = document.querySelector("header.header")
    ? document.querySelector("header.header").clientHeight
    : 65;

  return () => {
    const { pageYOffset: top } = window;
    const playZoneTop = top;
    const playZoneBottom = top + height;
    if (prevPositionY > top) {
      scrollTop(
        listComponents,
        listVideos,
        playZoneTop + headerHeight,
        playZoneBottom,
      );
    }

    if (prevPositionY < top) {
      scrollBottom(listComponents, listVideos, playZoneTop, playZoneBottom);
    }

    prevPositionY = top;
  };
};

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const sortComments = (array) => {
  const sortedArray = [];

  const addCommentToArray = (index, comment) => sortedArray.splice(index, 0, comment);
  const comment = (item) => addCommentToArray(0, item);
  const answer = (item) => sortedArray.map((answerItem, index) => {
    if (item.parentId === answerItem.id__normalized) return addCommentToArray(index + 1, item);
  });

  array.map((item) => (item.parentId === 0 || item.parentId === null ? comment(item) : answer(item)));

  return sortedArray;
};

export const commentLanguage = (comment) => {
  const regex = /[а-я]/g;
  const lengthLocaleLetter = comment.match(regex);
  return lengthLocaleLetter === null ? "en" : "ru";
};

export const pageWithoutLocale = (page) => {
  const isRuPage = page.startsWith("/ru");
  return isRuPage ? page.slice(3) : page;
};

export const getCommentsPage = (page) => page.slice(1, -1);

export const useWindowSize = (breakPoint = 812) => {
  const [lessThenBreakPoint, setlessThenBreakPoint] = useState(true);

  const handleMediaQueryChanges = (e) => setlessThenBreakPoint(e.matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${breakPoint}px)`);
    handleMediaQueryChanges(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChanges);
    return () => mediaQuery.removeListener(handleMediaQueryChanges);
  }, []);

  return lessThenBreakPoint;
};

export const createLinkPoints = (path) => {
  let linkString = path.slice(1, -1);
  if (linkString.startsWith("ru")) {
    linkString = linkString.slice(3);
  }
  const links = linkString.split("/");
  links.pop();
  if (links[0] !== "support") {
    links.unshift("support");
  }
  return links;
};
