export const isSaleCompany = false;
export const isPromoBanner = false;
export const banner = "promo"; // christmas || promo

export const colorsList = [
  "default",
  "primary",
  "primary-main",
  "secondary",
  "white",
  "remark",
  "gray",
  "gray-dark",
  "information",
  "light-blue",
  "gray-main",
];

export const subMenuURL = [
  "/excel-popup-calendar-toolkit/",
  "/excel-version-control-toolkit/",
  "/excel-data-preparation-toolkit/",
  "/excel-automation-toolkit/",
  "/excel-apps/",
];

export const subMenuUrls = {
  products: [
    "/excel-popup-calendar-toolkit/",
    "/excel-version-control-toolkit/",
    "/excel-data-preparation-toolkit/",
    "/excel-automation-toolkit/",
    "/excel-apps/",
  ],
  services: ["/services/automate-tasks-in-excel/", "/services/custom-add-in-development-for-office/"],
};

export const structureGoogleDataUrl = Object.values(subMenuUrls)
  .flat()
  .concat("/");

export const supportURL = [
  // ____________________________________support
  "/support/",
  "/support/how-to-download-and-install/",
  "/support/how-to-activate-license/",
  "/support/how-to-install-updates/",
  "/support/how-to-uninstall-and-reinstall/",
  "/support/how-to-purchase-a-license/",
  "/support/how-to-purchase-for-russian-companies/",
  "/support/how-to-purchase-an-upgrade/",
  "/support/getting-started/",
  "/support/faq/",
  "/support/troubleshooting/",
  "/support/release-notes/",
  "/support/license-key-reminder/",
  "/support/download-older-version/",
  "/support/useful-excel-links/",
  "/support/how-to-add-remove-online-app/",
  "/support/useful-excel-links",
  "/unsubscribe/",
  // _______________________________support-doc
  "/excel-popup-calendar/",
  "/excel-popup-clock/",
  "/data-cleansing-in-excel/",
  "/excel-add-characters/",
  "/excel-delete-characters/",
  "/excel-replace-characters/",
  "/excel-fill-blank-cells/",
  "/excel-convert-numbers-stored-as-text/",
  "/excel-change-text-case/",
  "/excel-count-aggregate-by-color/",
  "/excel-columns-match/",
  "/excel-workbook-organizer/",
  "/excel-combine-data-from-sheets/",
  "/excel-unpivot-table/",
  "/excel-split-table/",
  "/excel-combine-cells-merge-data/",
  "/alt-tab-equivalent-for-excel-sheets/",
  "/excel-repeat-recurring-selection/",
  "/disable-f1-f9-in-excel/",
  "/custom-shortcuts/",
  "/sql-queries-in-excel/",
  "/excel-export-to-csv/",
  "/mail-merge-in-excel/",
  "/excel-version-control/",
  "/no-vba-automation/",
  "/no-vba-automation/export-to-csv/",
  "/no-vba-automation/extract-table/",
  "/no-vba-automation/flat-text-to-table/",
  "/no-vba-automation/recalculate-range/",
  "/no-vba-automation/save-sheets-as-files/",
  "/no-vba-automation/split-table/",
  "/no-vba-automation/unify-date-time/",
  "/no-vba-automation/unpivot-table/",
  "/version-control-for-vba-macros/",
  "/excel-embedded-calendar/",
  "/excel-unify-change-date-format/",
  "/excel-add-text-to-cells/",
  "/excel-delete-text-from-cells/",
  "/excel-number-stored-as-text/",
  "/excel-loop-macro/",
  "/excel-recalculate-formulas-in-range/",
  "/xl-functions/",
  "/xl-functions/random/",
  "/xl-functions/date-add/",
  "/xl-functions/date-to-ticks/",
  "/xl-functions/find-email/",
  "/xl-functions/find-number/",
  "/xl-functions/format-phone-number/",
  "/xl-functions/count-words/",
  "/xl-functions/concatenate/",
  "/xl-functions/string-format/",
  "/xl-functions/format-date/",
  "/xl-functions/compute-md5-hash/",
  "/xl-functions/hex-color-to-rgb/",
  // _____________________________online-apps
  "/excel-apps/calendar-app/",
  "/excel-apps/data-cleaning-app/",
  "/excel-apps/unpivot-table-app/",
  "/excel-apps/csv-export-app/",
  "/excel-apps/columns-match-app/",
  "/excel-apps/sql-queries-in-excel-app/",
  "/excel-apps/sendgrid-reports-app/",
];

export const postURL = [
  "/blog/excel-template-dividend-forecast/",
  "/blog/meet-new-website-fresh-fast-helpful/",
  "/blog/excel-case-automate-order-processing/",
  "/blog/excel-case-automate-export-to-mysql/",
  "/blog/track-changes-shared-excel-workbook/",
  "/blog/three-useful-habits-in-excel/",
  "/blog/webinar-features-overview/",
  "/blog/remove-leading-spaces-in-excel/",
];

export const metaIcon = {
  date: {
    white: {
      iconName: "calendar",
      iconDir: "post/icons",
    },
    gray: {
      iconName: "calendar-gray",
      iconDir: "post/icons",
    },
  },
  time: {
    white: {
      iconName: "clock",
      iconDir: "post/icons",
    },
    gray: {
      iconName: "clock-gray",
      iconDir: "post/icons",
    },
  },
  views: {
    white: {
      iconName: "eye",
      iconDir: "post/icons",
    },
  },
  comments: {
    white: {
      iconName: "comment",
      iconDir: "post/icons",
    },
  },
};

export const purchaseValue = {
  14.95: [11.45, 10.95, 10.45, 8.95],
  99.95: [79.95, 74.95, 69.95, 59.95],
  149.95: [119.95, 111.95, 104.95, 89.95],
  199.95: [159.95, 149.95, 139.95, 119.95],
};

export const weightList = ["light", "normal", "medium", "bold", "black"];

export const sizesList = ["large", "normal", "small"];

export const clientsList = [
  {
    img: "cannon-technologies",
    alt: "Cannon Technologies",
  },
  {
    img: "cathay-pacific",
    alt: "Cathay Pacific",
  },
  {
    img: "takeda",
    alt: "Takeda",
  },
  {
    img: "proctor-and-gamble",
    alt: "Procter & Gamble",
  },
  {
    img: "johnson-and-johnson",
    alt: "Johnson & Johnson",
  },
  {
    img: "alfa-bank",
    alt: "Alfa Bank",
  },
  {
    img: "pwc",
    alt: "PwC",
  },
  {
    img: "accenture",
    alt: "Accenture",
  },
  {
    img: "coopertires",
    alt: "Coopertires",
  },
  {
    img: "sanko",
    alt: "Sanko",
  },
  {
    img: "danfoss",
    alt: "Danfoss",
  },
  {
    img: "tinkoff-bank",
    alt: "Tinkoff Bank",
  },
];
